import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControl,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Typography
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { Link, useLocation, useNavigate } from "react-router-dom";
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import bannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import AdvertisingBannner from "../advertisingBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import Loader from "src/components/Loader";
import { Config, fetchFromStorage } from "src/helpers/context";
import OurPartner from "../ourPartners";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DefaultImg from "../../../assets/images/smartb_default.png";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Datepicker } from "src/assets/images/eventcalendar/Datepicker.svg";
import { ReactComponent as Timepicker } from "src/assets/images/eventcalendar/Timepicker.svg";
import { ReactComponent as Unchecked } from "../../../assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Share } from "../../../assets/images/icons/share.svg";
import AmericanFootball from "../../../assets/images/SportIcon/outlineSportIcon/AmericanFootball.svg";
import AustralianRules from "../../../assets/images/SportIcon/outlineSportIcon/AustralianRules.svg";
import Baseball from "../../../assets/images/SportIcon/outlineSportIcon/Baseball.svg";
import Basketball from "../../../assets/images/SportIcon/outlineSportIcon/Basketball.svg";
import Boxing from "../../../assets/images/SportIcon/outlineSportIcon/Boxing.svg";
import Cricket from "../../../assets/images/SportIcon/outlineSportIcon/Cricket.svg";
import Golf from "../../../assets/images/SportIcon/outlineSportIcon/Golf.svg";
import IceHockey from "../../../assets/images/SportIcon/outlineSportIcon/IceHockey.svg";
import MixedMartialArts from "../../../assets/images/SportIcon/outlineSportIcon/MixedMartialArts.svg";
import RugbyLeague from "../../../assets/images/SportIcon/outlineSportIcon/RugbyLeague.svg";
import RugbyUnion from "../../../assets/images/SportIcon/outlineSportIcon/RugbyUnion.svg";
import Soccer from "../../../assets/images/SportIcon/outlineSportIcon/Soccer.svg";
import Tennis from "../../../assets/images/SportIcon/outlineSportIcon/Tennis.svg";
import { ReactComponent as TooltipArrow } from "src/assets/images/icons/tooltip_arrow.svg";
import { ReactComponent as TickIcon } from "src/assets/images/icons/tick.svg";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { ReactComponent as RightArrow } from "../../../assets/images/icons/mycomp-right-arrow.svg";
import { ToastContainer, toast } from "react-toastify";
import NotepadBig from "../../../assets/images/notepadBig.png";
import Facebook from "../../../assets/images/social/fb-link.svg";
import Linkedin from "../../../assets/images/social/li-link.svg";
import Twitter_X from "../../../assets/images/social/x-link.svg";
import Snapchat from "../../../assets/images/social/sc-link.svg";
import Copy from "../../../assets/images/social/copy-link.svg";
import Messenger from "../../../assets/images/social/mess-link.svg";
import Wp from "../../../assets/images/social/wp-link.svg";
import Message from "../../../assets/images/social/messages.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";
import moment from "moment";
import CompHeader from "../../../assets/images/comp-header.png";
import AdBannner from "../AdBanner/AdBanner";
import NoDataComp from "src/views/component/UI/NoData";
import { Formik } from "formik";
import Select, { components } from "react-select";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import DateFnsUtils from "@date-io/date-fns";
// import { parseISO } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
  KeyboardTimePicker,
  TimePicker
} from "@material-ui/pickers";
import "./myComps.scss";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import CompJoinModal from "../UI/compJoinModal";
import DialogBox from "../UI/dialogBox";
import { release } from "src/helpers/context/release";

const compsTypeData = [
  { value: null, label: "All" },
  { value: "my", label: "My" },
  { value: "joined", label: "Joined" },
  { value: "public", label: "Public" }
];
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const MyCompsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const redux_userData = useSelector(
    state => state?.reduxData?.SubscripitionData
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [clickToolTipTeamId, setClickToolTipTeamId] = useState(null);
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState(null);
  const [teamsAll, setTeamsAll] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [OrgAll, setOrgAll] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [countOrg, setcountOrg] = useState(0);
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [selectedCompsType, setSelectedCompsType] = useState(0);
  const [isCompsLoading, setIsCompsLoading] = useState(false);
  const [allComps, setAllComps] = useState([]);
  const [compCount, setCompCount] = useState(0);
  const [compPage, setCompPage] = useState(1);
  const [compById, setCompsById] = useState({});
  const [isJoined, setIsJoined] = useState(false);
  const [openJoinModal, setOpenJoinModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const rowperPage = 10;
  useEffect(() => {
    if (code) {
      fetchCompsByCode();
    } else {
      fetchPrivateComp(0, 0, null, 1, "");
    }

    fetchSportData();
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    // Load the Facebook SDK
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: "708883073941381",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v18.0"
      });
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const handleJoinModal = () => {
    setOpenJoinModal(true);
    setOpenConfirm(false);
  };

  const handleJoinModalClose = () => {
    setOpenJoinModal(false);
  };

  const handleOpenConfirmModal = () => {
    setOpenJoinModal(false);
    setOpenConfirm(true);
  };
  const handleConfirmClose = () => {
    setOpenConfirm(false);
  };

  const fetchPrivateComp = async (
    sports,
    tournament,
    type,
    page,
    search,
    signal
  ) => {
    setCompPage(page);
    try {
      setIsCompsLoading(true);
      const {
        status,
        data
      } = await axiosInstance.get(
        `tipping/getAll?SportId=${
          sports
            ? sports
            : Config.release === "IN"
            ? release[Config.release]?.sportId?.toString()
            : ""
        }&tournamentId=${tournament ? tournament : ""}&privacy=private&type=${
          type ? type : ""
        }&limit=${rowperPage}&page=${page}&search=${search}`,
        { signal }
      );
      if (status === 200) {
        setAllComps(data?.result);
        setCompCount(data?.count);
        setIsCompsLoading(false);
      } else {
        setIsCompsLoading(false);
      }
    } catch (err) {
      setIsCompsLoading(false);
    }
  };

  const fetchCompsByCode = async () => {
    try {
      setIsCompsLoading(true);
      const { status, data } = await axiosInstance.get(
        `tipping/getByCode/${code}`
      );
      if (status === 200) {
        // setAllComps(data?.result);
        // setCompCount(data?.count);
        setCompsById(data?.result);
        setIsJoined(data?.isJoined);
        if (
          redux_userData?.id !== data?.result?.UserId &&
          data?.isJoined === false
        ) {
          handleJoinModal();
        }

        setIsCompsLoading(false);
      } else {
        setIsCompsLoading(false);
      }
    } catch (err) {
      setIsCompsLoading(false);
    }
  };

  const fetchSingleCompByCode = compData => {
    const item = compById;
    const tournamentSlug = item?.Tournament?.name
      ? item?.Tournament?.name
          .trim()
          .toLowerCase()
          .replaceAll(" ", "-")
      : "tournament";
    const sportSlug = item?.Sport?.sportName
      ? item?.Sport?.sportName
          .trim()
          .toLowerCase()
          .replaceAll(" ", "-")
      : "sports";
    const aboutLines = item?.about?.split("\n");
    return (
      <>
        <Box className="page-deatils-wrap">
          <Box
            className="my-comp-sec-v2"
            onClick={() => {
              navigateToComp(
                sportSlug,
                tournamentSlug,
                item?.SportId,
                item?.Tournament?.id,
                item?.id
              );
              // handleCalculateLadder(item?.id);
            }}
          >
            <Box className="my-comp-left">
              <Box className="top-sec">
                <Box className="left-top-sec">
                  <Box className="sport-icon-sec">
                    {sportIcon(item?.SportId)}
                  </Box>
                  <Typography className="tournament-txt">
                    {item?.Sport?.sportName}
                  </Typography>
                  <Box className="chip-sec">
                    <Typography className="chip-txt">
                      {item?.Tournament?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box className="right-top-sec">
                  <span className="rank-des">Rank :</span>{" "}
                  <span className="rank-val">
                    {" "}
                    {item?.userRank}/{item?.totalTipper}
                  </span>
                </Box>
              </Box>
              <Box className="bottom-sec">
                <Box className="left-sec">
                  <Box className="left-sec-image-des">
                    <Box>
                      <Box className="profile-img-wrap">
                        <img
                          src={
                            item?.Media?.filePath
                              ? Config.countryMediaURL + item?.Media?.filePath
                              : DefaultImg
                          }
                          alt="profile"
                        />
                      </Box>
                      {redux_userData?.id !== item?.UserId && (
                        <Box className="right-btn-wrap">
                          <Button
                            className={
                              isJoined
                                ? `join-btn disable-join-btn`
                                : "join-btn"
                            }
                            onClick={e => handleAddComps(e, item)}
                            startIcon={isJoined ? <TickIcon /> : <></>}
                            disabled={isJoined}
                          >
                            {isJoined ? "Joined" : "Join"}
                          </Button>
                        </Box>
                      )}
                    </Box>

                    <Box className="name-time" style={{ marginLeft: "5px" }}>
                      <Box className="my-comp-icon-name-wrap">
                        <Typography className="sport-name">
                          {/* {sportName(item?.SportId)} */}
                          {item?.competitionName}
                        </Typography>
                      </Box>
                      {item?.about && (
                        <Typography className="about-comp-dec">
                          <span>About Comp: </span>
                          {/* {item?.about} */}
                          {aboutLines?.map((line, index) => (
                            <p key={index} className="about-line">
                              {line} <br />
                            </p>
                          ))}
                        </Typography>
                      )}
                      <Typography className="final-txt">
                        {item?.SportId == 4 ? "Day" : "Round"}{" "}
                        {item?.startingRound}{" "}
                        {item?.tipDeadline == "game"
                          ? " Game Based"
                          : `closes at:`}
                        {item?.tipDeadline == "round" && (
                          <span>
                            {" "}
                            <Box className="comp-header-details">
                              <Typography className="day-time-details">
                                <span>{fetchDayandTime(item)}</span>
                              </Typography>
                            </Box>
                          </span>
                        )}
                      </Typography>
                      {item?.User?.firstName && (
                        <Typography className="final-txt">
                          Created By:{" "}
                          <span className="day-time-details">
                            {item?.User?.firstName + " " + item?.User?.lastName}{" "}
                          </span>
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="my-comp-right">
              <Box>
                <RightArrow className="right-arrow" />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const handleAddComps = async (e, item) => {
    e.stopPropagation();
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    const tournamentSlug = item?.Tournament?.name
      ? item?.Tournament?.name
          .trim()
          .toLowerCase()
          .replaceAll(" ", "-")
      : "tournament";
    const sportSlug = item?.Sport?.sportName
      ? item?.Sport?.sportName
          .trim()
          .toLowerCase()
          .replaceAll(" ", "-")
      : "sports";
    if (isLogin) {
      try {
        const { status, data } = await axiosInstance.put(
          `/tipping/addLadder/${code}`
        );
        if (status === 200) {
          toast.success("Comps Added Successfully", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored"
          });
          navigate(
            `/tipscompetition/my-comps/${sportSlug}/${tournamentSlug}/${item?.SportId}/${item?.Tournament?.id}/${item?.id}`
          );
        } else {
          // handleOpenConfirmModal();
        }
      } catch (err) {}
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem(
        "prev_authroute",
        window.location.pathname + window.location.search
      );
      handleOpenConfirmModal();
    }
  };

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`
      );
      if (status === 200) {
        const FilteredSportData = data?.result?.filter(
          item => item?.id == 12 || item?.id == 9 || item?.id == 4
        );
        const releaseFilterSportData = FilteredSportData?.filter(item =>
          release[Config.release]?.sportId?.includes(item?.id)
        );
        var sportsdata = releaseFilterSportData?.map(s => {
          return { ...s, label: s?.sportName, value: s?.id };
        });
        var sdata = _.orderBy(sportsdata, ["label"], ["asc"]);
        var mergedArray = _.concat(
          [{ label: "All Sports", value: null, isAll: true }],
          sdata
        );
        setSports(mergedArray);
      }
    } catch (err) {}
  };
  const fetchOrgData = async (page, sID, OrgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/tournament?SportId=${
          sID ? sID : ""
        }&offset=${page}&limit=${20}`
      );
      if (status === 200) {
        setOrgApiCount(OrgApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(OrgAll, newdata);
        // let allteamdatas = filterData?.unshift({
        //   label: "All Tournaments",
        //   value: 0
        // });
        setOrgAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {}
  };

  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/tournament?SportId=${
          sid ? sid : ""
        }&limit=${20}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };

  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, selectedSports);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20 + 1)) {
        fetchOrgData(pageOrg + 20, selectedSports, OrgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  // <Box style={{ display: "flex", alignItems: "center" }}>
  //   {sportIcon(item?.SportId)}

  //   <p>
  //     <span className="title-txt">{sportName(item?.SportId)}</span>
  //   </p>
  // </Box>;

  const handleTooltipContentClick = (event, teamId, objindex) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    setHoveredIndex(objindex);
    setClickToolTipTeamId(teamId);
  };
  const handleCopy = () => {
    toast.success("Link Copied Successfully", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored"
    });
  };

  const shareOnMessenger = link => {
    if (window.FB) {
      window.FB.ui({
        method: "send", // share in facebook post and send in facebook messenger
        link: link // link in facebook messenger and href in facebook post
      });
    } else {
      console.error("Facebook SDK not loaded.");
    }
  };

  const handleOutsideClick = (item, teamId, index) => {
    if (hoveredIndex === index && clickToolTipTeamId === teamId) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
  };

  const handleOpenTooltip = (item, teamId, index) => {
    const link =
      Config.baseURL.replace("/api", "") +
      `tipscompetition/private-comps?code=${item?.code}`;
    return (
      <Box
        className="comp-custom-tooltip right-sec"
        onClick={e => handleTooltipContentClick(e, teamId, index)}
      >
        {/* <Box> */}
        {screenWidth >= 799 ? (
          <Button className="share-btn" endIcon={<Share />}>
            Share
          </Button>
        ) : (
          <Button className="share-btn" endIcon={<Share />}></Button>
        )}
        {/* </Box> */}
        {hoveredIndex === index && clickToolTipTeamId === teamId ? (
          tooltipOpen && (
            <Box className="tooltip">
              <Box style={{ position: "relative" }}>
                <TooltipArrow className="tooltip-arrow" />
              </Box>
              <Box className="share-option">
                <Box className="social-option-box">
                  <Box className="option">
                    <CopyToClipboard text={link} onCopy={handleCopy}>
                      <Box className="cursor-pointer">
                        <img src={Copy} alt="Copy" />
                        <Typography className="option-name">
                          Copy Link
                        </Typography>
                      </Box>
                    </CopyToClipboard>
                  </Box>
                  <Box className="option">
                    <WhatsappShareButton url={link}>
                      <img src={Wp} alt="Whatsapp" />
                    </WhatsappShareButton>
                    <Typography className="option-name">Whatsapp</Typography>
                  </Box>
                  <Box className="option">
                    <FacebookShareButton url={link}>
                      <img src={Facebook} alt="Facebook" />
                    </FacebookShareButton>
                    <Typography className="option-name">Facebook</Typography>
                  </Box>
                  <Box className="option cursor-pointer">
                    <img
                      src={Messenger}
                      alt="Messenger"
                      className="social-icon"
                      onClick={() => shareOnMessenger(link)}
                    />
                    <Typography className="option-name">Messenger</Typography>
                  </Box>
                  <Box className="option">
                    <TwitterShareButton url={link}>
                      <img src={Twitter_X} alt="Twitter_X" />
                    </TwitterShareButton>
                    <Typography className="option-name">Twitter</Typography>
                  </Box>
                  <Box className="option">
                    <LinkedinShareButton url={link}>
                      <img src={Linkedin} alt="Linkedin" />
                    </LinkedinShareButton>
                    <Typography className="option-name">Linkedin</Typography>
                  </Box>
                  {/* <Box className="option">
                    <img src={Snapchat} alt="Snapchat" />
                    <Typography className="option-name">Snapchat</Typography>
                  </Box>
                  <Box className="option">
                    {" "}
                    <img src={Message} alt="Message" />
                    <Typography className="option-name">Message</Typography>
                  </Box> */}
                </Box>
              </Box>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const sportIcon = sportId => {
    if (sportId === 15) {
      return <img src={AmericanFootball} alt="" />;
    } else if (sportId === 9) {
      return <img src={AustralianRules} alt="" />;
    } else if (sportId === 11) {
      return <img src={Baseball} alt="" />;
    } else if (sportId === 10) {
      return <img src={Basketball} alt="" />;
    } else if (sportId === 6) {
      return <img src={Boxing} alt="" />;
    } else if (sportId === 4) {
      return <img src={Cricket} alt="" />;
    } else if (sportId === 16) {
      return <img src={Golf} alt="" />;
    } else if (sportId === 17) {
      return <img src={IceHockey} alt="" />;
    } else if (sportId === 5) {
      return <img src={MixedMartialArts} alt="" />;
    } else if (sportId === 12) {
      return <img src={RugbyLeague} alt="" />;
    } else if (sportId === 13) {
      return <img src={RugbyUnion} alt="" />;
    } else if (sportId === 8) {
      return <img src={Soccer} alt="" />;
    } else if (sportId === 7) {
      return <img src={Tennis} alt="" />;
    }
  };

  const sportName = sportId => {
    if (sportId === 15) {
      return "American Football";
    } else if (sportId === 9) {
      return "Australian Rules";
    } else if (sportId === 11) {
      return "Baseball";
    } else if (sportId === 10) {
      return "Basketball";
    } else if (sportId === 6) {
      return "Boxing";
    } else if (sportId === 4) {
      return "Cricket";
    } else if (sportId === 16) {
      return "Golf";
    } else if (sportId === 17) {
      return "Ice Hockey";
    } else if (sportId === 5) {
      return "Mixed Martial Arts";
    } else if (sportId === 12) {
      return "Rugby League";
    } else if (sportId === 13) {
      return "Rugby Union";
    } else if (sportId === 8) {
      return "Soccer";
    } else if (sportId === 7) {
      return "Tennis";
    }
  };
  const handlePaginationClick = (event, page) => {
    fetchPrivateComp(
      selectedSports,
      selectedOrg,
      selectedCompsType,
      Number(page),
      searchInput
    );
  };
  const pageNumbers = [];

  if (compCount > 0) {
    for (let i = 1; i <= Math.ceil(compCount / rowperPage); i++) {
      pageNumbers.push(i);
    }
  }
  const navigateToComp = (
    sports,
    leagueName,
    SportId,
    tournamentId,
    compsId
  ) => {
    if (!code) {
      navigate(
        `/tipscompetition/my-comps/${sports}/${leagueName}/${SportId}/${tournamentId}/${compsId}`
      );
    } else {
      if (isJoined) {
        navigate(
          `/tipscompetition/my-comps/${sports}/${leagueName}/${SportId}/${tournamentId}/${compsId}`
        );
      }
    }
  };

  const handleCalculateLadder = async compsId => {
    try {
      const { status, data } = await axiosInstance.put(
        `/tipping/calculate/ladder/${compsId}`
      );
      if (status === 200) {
      }
    } catch (err) {}
  };
  const fetchDayandTime = item => {
    if (item?.cutOffTime) {
      const dayWithTime = moment
        .utc(`${item?.cutOffWeek} ${item?.cutOffTime}`, "dddd HH:mm:ss")
        .tz(timezone)
        .format("h:mm A");

      return item?.cutOffWeek + " | " + dayWithTime;
    }
  };

  const hadleInputChange = value => {
    setSearchInput(value);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    fetchPrivateComp(
      selectedSports,
      selectedOrg,
      selectedCompsType,
      1,
      searchInput,
      signal
    );

    return () => {
      abortController.abort();
    };
  }, [searchInput]);

  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {fetchPageHeaderAds(
            "218px",
            Config?.release == "IN" ? bannerIndia : banner
          )}
        </Box>
        <Box className="my-comp-wrap">
          <Box className="my-comp-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  HOME
                </Link>

                <Link
                  underline="hover"
                  color="inherit"
                  to={location?.pathname + location?.search}
                  className="cursor-default"
                >
                  Tipping Competition
                </Link>
                {/* {code && (
                  <Link underline="hover" color="inherit" to="">
                    My Comps
                  </Link>
                )} */}
                <Typography>
                  {code
                    ? `${compById && compById?.competitionName}`
                    : `My Comps`}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">
              {code ? `${compById && compById?.competitionName}` : `My Comps`}
            </Typography>
          </Box>
          {!code && (
            <>
              <Box className="tab-search-section">
                <Box className="search-filter-wrap">
                  <TextField
                    placeholder="Search (by comp name)"
                    variant="outlined"
                    type="text"
                    name="search"
                    className="search_input"
                    onChange={e => {
                      hadleInputChange(e?.target?.value);
                    }}
                    value={searchInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                <Box className="filter-section">
                  <Select
                    className={
                      selectedSports ||
                      selectedOrg ||
                      selectedCompsType ||
                      allComps?.length !== 0
                        ? `React sort-select `
                        : `React sort-select disable-state`
                    }
                    value={sports?.find(item => {
                      return item?.value === selectedSports;
                    })}
                    onChange={e => {
                      setSelectedSports(e?.value == 0 ? "" : e?.value);
                      setCurrentPage(0);
                      setSelectedOrg(null);
                      setOrgAll([]);
                      fetchPrivateComp(
                        e?.value,
                        null,
                        selectedCompsType,
                        1,
                        searchInput
                      );
                      if (e?.value) {
                        fetchOrgData(0, e?.value, []);
                      }
                    }}
                    // onMenuScrollToBottom={e => handleOnScrollBottomSports(e)}
                    options={sports}
                    classNamePrefix="select"
                    placeholder="Select sport"
                    // isSearchable={false}
                    // onFocus={() => setTournamentSelect(true)}
                    // onBlur={() => setTournamentSelect(false)}
                    components={{ DropdownIndicator }}
                    isDisabled={
                      selectedSports ||
                      selectedOrg ||
                      selectedCompsType ||
                      allComps?.length !== 0
                        ? false
                        : true
                    }
                  />
                  <Select
                    className={
                      selectedSports
                        ? `React sort-select `
                        : `React sort-select disable-state`
                    }
                    onMenuScrollToBottom={e => handleOnScrollBottomOrg(e)}
                    onInputChange={e =>
                      handleOrgInputChange(0, e, selectedSports)
                    }
                    onChange={e => {
                      setSelectedOrg(e?.value);
                      setOrgApiCount(0);
                      setpageOrg(0);
                      setisOrgSelectOpen(false);
                      setCurrentPage(0);
                      fetchPrivateComp(
                        selectedSports,
                        e?.value,
                        selectedCompsType,
                        1,
                        searchInput
                      );
                    }}
                    onFocus={() => setisOrgSelectOpen(true)}
                    onBlur={() => setisOrgSelectOpen(false)}
                    value={
                      selectedOrg &&
                      (isOrgSearch
                        ? searchOrg?.find(item => {
                            return item?.value == selectedOrg;
                          })
                        : OrgAll?.find(item => {
                            return item?.value == selectedOrg;
                          }))
                    }
                    options={isOrgSearch ? searchOrg : OrgAll}
                    classNamePrefix="select"
                    placeholder="Select league"
                    isDisabled={selectedSports ? false : true}
                    components={{ DropdownIndicator }}
                  />
                  <Select
                    className={
                      selectedSports ||
                      selectedOrg ||
                      selectedCompsType ||
                      allComps?.length !== 0
                        ? `React sort-select `
                        : `React sort-select disable-state`
                    }
                    value={compsTypeData?.find(item => {
                      return item?.value === selectedCompsType;
                    })}
                    onChange={e => {
                      setSelectedCompsType(e?.value);
                      fetchPrivateComp(
                        selectedSports,
                        selectedOrg,
                        e?.value,
                        1,
                        searchInput
                      );
                    }}
                    options={compsTypeData}
                    classNamePrefix="select"
                    placeholder="All"
                    components={{ DropdownIndicator }}
                    isDisabled={
                      selectedSports ||
                      selectedOrg ||
                      selectedCompsType ||
                      allComps?.length !== 0
                        ? false
                        : true
                    }
                  />
                </Box>
              </Box>
            </>
          )}

          {isCompsLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : !code ? (
            allComps?.length > 0 ? (
              <Box className="page-deatils-wrap">
                {allComps?.map((item, index) => {
                  const aboutLines = item?.about?.split("\n");
                  const tournamentSlug = item?.Tournament?.name
                    ? item?.Tournament?.name
                        .trim()
                        .toLowerCase()
                        .replaceAll(" ", "-")
                    : "tournament";
                  const sportSlug = item?.Sport?.sportName
                    ? item?.Sport?.sportName
                        .trim()
                        .toLowerCase()
                        .replaceAll(" ", "-")
                    : "sports";
                  return (
                    // <Box
                    //   className="my-comp-sec"
                    //   onClick={() => {
                    //     navigateToComp(
                    //       sportSlug,
                    //       tournamentSlug,
                    //       item?.SportId,
                    //       item?.Tournament?.id,
                    //       item?.id
                    //     );
                    //     handleCalculateLadder(item?.id);
                    //   }}
                    // >
                    //   <Box className="top-sec">
                    //     <Typography className="tournament-txt">
                    //       {item?.Sport?.sportName}
                    //     </Typography>
                    //     <Box className="chip-sec">
                    //       <Typography className="chip-txt">
                    //         {item?.Tournament?.name}
                    //       </Typography>
                    //     </Box>
                    //   </Box>
                    //   <Box className="bottom-sec">
                    //     <Box className="left-sec">
                    //       <Box className="left-sec-image-des">
                    //         <Box>
                    //           <Box className="profile-img-wrap">
                    //             <img
                    //               src={
                    //                 item?.Media?.filePath
                    //                   ? Config.countryMediaURL +
                    //                     item?.Media?.filePath
                    //                   : DefaultImg
                    //               }
                    //               alt="profile"
                    //             />
                    //           </Box>
                    //           {item?.isShare ? (
                    //             <Box className="right-btn-wrap">
                    //               {/* <Button
                    //         className={
                    //           item?.isJoined
                    //             ? `join-btn disable-join-btn`
                    //             : "join-btn"
                    //         }
                    //         // onClick={handleTippedClick}
                    //         startIcon={item?.isJoined ? <TickIcon /> : <></>}
                    //       >
                    //         {item?.isJoined ? "Joined" : "Join"}
                    //       </Button> */}

                    //               <ClickAwayListener
                    //                 onClickAway={() =>
                    //                   handleOutsideClick(item, item?.id, index)
                    //                 }
                    //               >
                    //                 <Box className="share-tooltip-icon">
                    //                   {handleOpenTooltip(item, item?.id, index)}
                    //                 </Box>
                    //               </ClickAwayListener>
                    //             </Box>
                    //           ) : (
                    //             <></>
                    //           )}
                    //         </Box>

                    //         <Box
                    //           className="name-time"
                    //           style={{ marginLeft: "5px" }}
                    //         >
                    //           <Box className="my-comp-icon-name-wrap">
                    //             <Box className="sport-icon-sec">
                    //               {sportIcon(item?.SportId)}
                    //             </Box>
                    //             <Typography className="sport-name">
                    //               {/* {sportName(item?.SportId)} */}
                    //               {item?.competitionName}
                    //             </Typography>
                    //           </Box>
                    //           {item?.about && (
                    //             <Typography className="about-comp-dec">
                    //               <span>About This Comp: </span>
                    //               {/* {item?.about} */}
                    //               {aboutLines?.map((line, index) => (
                    //                 <p key={index} className="about-line">
                    //                   {line} <br />
                    //                 </p>
                    //               ))}
                    //             </Typography>
                    //           )}
                    //           <Typography className="final-txt">
                    //             {item?.SportId == 4 ? "Day" : "Round"}{" "}
                    //             {item?.startingRound}{" "}
                    //             {item?.tipDeadline == "game"
                    //               ? " Game Based"
                    //               : `closes at:`}
                    //             {item?.tipDeadline == "round" && (
                    //               <span>
                    //                 {" "}
                    //                 <Box className="comp-header-details">
                    //                   <Typography className="day-time-details">
                    //                     <span>{fetchDayandTime(item)}</span>
                    //                   </Typography>
                    //                 </Box>
                    //               </span>
                    //             )}
                    //           </Typography>
                    //           {item?.User?.firstName && (
                    //             <Typography className="final-txt">
                    //               Created By:{" "}
                    //               <span className="day-time-details">
                    //                 {item?.User?.firstName +
                    //                   " " +
                    //                   item?.User?.lastName}{" "}
                    //               </span>
                    //             </Typography>
                    //           )}
                    //         </Box>
                    //       </Box>
                    //     </Box>
                    //   </Box>
                    // </Box>
                    <Box
                      className="my-comp-sec-v2"
                      onClick={() => {
                        navigateToComp(
                          sportSlug,
                          tournamentSlug,
                          item?.SportId,
                          item?.Tournament?.id,
                          item?.id
                        );
                        // handleCalculateLadder(item?.id);
                      }}
                    >
                      <Box className="my-comp-left">
                        <Box className="top-sec">
                          <Box className="left-top-sec">
                            <Box className="sport-icon-sec">
                              {sportIcon(item?.SportId)}
                            </Box>
                            <Typography className="tournament-txt">
                              {item?.Sport?.sportName}
                            </Typography>
                            <Box className="chip-sec">
                              <Typography className="chip-txt">
                                {item?.Tournament?.name}
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="right-top-sec">
                            <span className="rank-des">Rank :</span>{" "}
                            <span className="rank-val">
                              {item?.userRank}/{item?.totalTipper}
                            </span>
                          </Box>
                        </Box>
                        <Box className="bottom-sec">
                          <Box className="left-sec">
                            <Box className="left-sec-image-des">
                              <Box>
                                <Box className="profile-img-wrap">
                                  <img
                                    src={
                                      item?.Media?.filePath
                                        ? Config.countryMediaURL +
                                          item?.Media?.filePath
                                        : DefaultImg
                                    }
                                    alt="profile"
                                  />
                                </Box>

                                <Box className="right-btn-wrap">
                                  {/* <Button
                            className={
                              item?.isJoined
                                ? `join-btn disable-join-btn`
                                : "join-btn"
                            }
                            // onClick={handleTippedClick}
                            startIcon={item?.isJoined ? <TickIcon /> : <></>}
                          >
                            {item?.isJoined ? "Joined" : "Join"}
                          </Button> */}

                                  <ClickAwayListener
                                    onClickAway={() =>
                                      handleOutsideClick(item, item?.id, index)
                                    }
                                  >
                                    <Box className="share-tooltip-icon">
                                      {handleOpenTooltip(item, item?.id, index)}
                                    </Box>
                                  </ClickAwayListener>
                                </Box>
                              </Box>

                              <Box
                                className="name-time"
                                style={{ marginLeft: "5px" }}
                              >
                                <Box className="my-comp-icon-name-wrap">
                                  <Typography className="sport-name">
                                    {/* {sportName(item?.SportId)} */}
                                    {item?.competitionName}
                                  </Typography>
                                </Box>
                                {item?.about && (
                                  <Typography className="about-comp-dec">
                                    <span>About This Comp: </span>
                                    {/* {item?.about} */}
                                    {aboutLines?.map((line, index) => (
                                      <p key={index} className="about-line">
                                        {line} <br />
                                      </p>
                                    ))}
                                  </Typography>
                                )}
                                <Typography className="final-txt">
                                  {item?.SportId == 4 ? "Day" : "Round"}{" "}
                                  {item?.startingRound}{" "}
                                  {item?.tipDeadline == "game"
                                    ? " Game Based"
                                    : `closes at:`}
                                  {item?.tipDeadline == "round" && (
                                    <span>
                                      {" "}
                                      <Box className="comp-header-details">
                                        <Typography className="day-time-details">
                                          <span>{fetchDayandTime(item)}</span>
                                        </Typography>
                                      </Box>
                                    </span>
                                  )}
                                </Typography>
                                {item?.User?.firstName && (
                                  <Typography className="final-txt">
                                    Created By:{" "}
                                    <span className="day-time-details">
                                      {item?.User?.firstName +
                                        " " +
                                        item?.User?.lastName}{" "}
                                    </span>
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="my-comp-right">
                        <Box>
                          <RightArrow className="right-arrow" />
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
                {!isCompsLoading && allComps?.length > 0 ? (
                  <Box className="pagination-section">
                    <Pagination
                      disabled={compCount / rowperPage > 1 ? false : true}
                      page={compPage}
                      onChange={(e, value) => handlePaginationClick(e, value)}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size="small"
                    />
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <NoDataComp />
            )
          ) : (
            fetchSingleCompByCode(allComps)
          )}
          <OurPartner />
        </Box>
      </Box>
      <DialogBox
        open={openConfirm}
        onClose={handleConfirmClose}
        handleClose={handleConfirmClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure you want to join the competition
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel blackbook-delete"
                  onClick={e => {
                    handleConfirmClose();
                    // handleDoneModal();
                    handleAddComps(e, compById);
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleConfirmClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      <CompJoinModal
        open={openJoinModal}
        handleClose={handleJoinModalClose}
        title={""}
        closeIcon={true}
        // shareCode={shareCode}
        handleOpenConfirmModal={handleOpenConfirmModal}
        handleJoin={handleAddComps}
        compById={compById}
      />
    </>
  );
};

export default MyCompsPage;
