import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Checkbox,
  TextField,
  ClickAwayListener
} from "@material-ui/core";
import { Config } from "src/helpers/context";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchFromStorage } from "src/helpers/context";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import DefaultImg from "src/assets/images/smartb_default.png";
import "./sportsOdds.scss";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";
const SportsOdds = ({ data, type, team, teamId, isScore }) => {
  const dispatch = useDispatch();
  const { setApiInfo } = useAutoSaveStore();
  const BetslipData = useSelector(state => state?.reduxData?.BetSlipData);
  const BookkeeperData = useSelector(state => state?.reduxData?.BookkeeperData);

  const oddsicon = (BookKeeperId, type, item, fullData, oddsType) => {
    const Identifiers =
      data?.SportId == 4
        ? fullData?.CricketIdentifiers
        : data?.SportId == 10
        ? fullData?.NBAIdentifiers
        : data?.SportId == 15
        ? fullData?.AFLIdentifiers
        : data?.SportId == 9
        ? fullData?.ARIdentifiers
        : data?.SportId == 16
        ? fullData?.GolfIdentifiers
        : data?.SportId == 7
        ? fullData?.TennisIdentifiers
        : data?.SportId == 11
        ? fullData?.BaseballIdentifiers
        : data?.SportId == 17
        ? fullData?.IceHockeyIdentifiers
        : data?.SportId == 6
        ? fullData?.BoxingIdentifiers
        : data?.SportId == 5
        ? fullData?.MMAIdentifiers
        : data?.SportId == 8
        ? fullData?.SoccerIdentifiers
        : fullData?.RLIdentifiers;
    const newData = Identifiers?.map(obj => {
      const BookkeeperObj = obj?.ApiProvider?.BookKeeperProviders?.filter(
        item => {
          return item?.BookKeeperId === BookKeeperId;
        }
      );
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null
      };
    });
    const filteredData = newData
      ?.filter?.(obj => obj?.BookKeeperId === BookKeeperId)
      ?.filter(obj => obj?.ApiProviderId !== 17);

    let icon = BookkeeperData?.filter(obj => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    let imageUrl =
      oddsType == "currentbest"
        ? iconData?.currentBest_logo
        : iconData?.long_logo;
    if (BookKeeperId === 8) {
      const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.filter(
        ele => ele?.providerMarketId && ele?.providerParticipantId
      );
      const urlLink =
        Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
          ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
          : `${iconData?.affiliate_link}`;
      return (
        <Box
          // href={
          //
          // }
          // target="_blank"
          // rel="noopener noreferrer"
          className="current-best-odds-icon"
          // onClick={e => handleAnchorTagClick(e, urlLink)}
        >
          {oddsType == "currentbest" ? (
            <img
              className="currentbest-bookmaker-thumb"
              src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
              onClick={e => {
                handleBookkeeperCounter(BookKeeperId, type, e);
                handleAnchorTagClick(e, urlLink);
              }}
              alt="Odds Icon"
            />
          ) : (
            <Box className="odd-img">
              <img
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                onClick={e => {
                  handleBookkeeperCounter(BookKeeperId, type, e);
                  handleAnchorTagClick(e, urlLink);
                }}
                alt="Odds Icon"
              />
            </Box>
          )}
        </Box>
      );
    } else {
      const urlLink =
        filteredData?.length > 0 && filteredData?.[0]?.url
          ? filteredData?.[0]?.url + `?Referrer=SmartB`
          : iconData?.affiliate_link;
      return (
        <Box
          // href={
          //   filteredData?.length > 0 && filteredData?.[0]?.url
          //     ? filteredData?.[0]?.url + `?Referrer=SmartB`
          //     : iconData?.affiliate_link
          // }
          // target="_blank"
          // rel="noopener noreferrer"
          className="current-best-odds-icon"
          // onClick={e => handleAnchorTagClick(e, urlLink)}
        >
          {oddsType == "currentbest" ? (
            <img
              className="currentbest-bookmaker-thumb"
              src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
              onClick={e => {
                handleBookkeeperCounter(BookKeeperId, type, e);
                handleAnchorTagClick(e, urlLink);
              }}
              alt="Odds Icon"
            />
          ) : (
            <Box className="odd-img">
              <img
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                onClick={e => {
                  handleBookkeeperCounter(BookKeeperId, type, e);
                  handleAnchorTagClick(e, urlLink);
                }}
                alt="Odds Icon"
              />
            </Box>
          )}
        </Box>
      );
    }
  };

  const fetchCurrentBestOdds = (data, type, team, teamid, Isscore) => {
    let allTeamOdds =
      data?.SportId == 4
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : data?.SportId == 10
        ? data?.NBABetOffers?.[0]?.NBAOdds
        : data?.SportId == 15
        ? data?.AFLBetOffers?.[0]?.AFLOdds
        : data?.SportId == 9
        ? data?.ARBetOffers?.[0]?.AROdds
        : data?.SportId == 16
        ? data?.GolfBetOffers?.[0]?.GolfOdds
        : data?.SportId == 7
        ? data?.TennisBetOffers?.[0]?.TennisOdds
        : data?.SportId == 11
        ? data?.BaseballBetOffers?.[0]?.BaseballOdds
        : data?.SportId == 17
        ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
        : data?.SportId == 6
        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
        : data?.SportId == 5
        ? data?.MMABetOffers?.[0]?.MMAOdds
        : data?.SportId == 8
        ? data?.SoccerBetOffers?.[0]?.SoccerOdds
        : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId == 4
        ? item?.CricketTeamId == data?.homeTeamId
        : data?.SportId == 10
        ? item?.NBATeamId == data?.homeTeamId
        : data?.SportId == 15
        ? item?.AFLTeamId == data?.homeTeamId
        : data?.SportId == 9
        ? item?.ARTeamId == data?.homeTeamId
        : data?.SportId == 16
        ? item?.GolfTeamId == data?.homeTeamId
        : data?.SportId == 7
        ? item?.TennisTeamId == data?.homeTeamId
        : data?.SportId == 11
        ? item?.BaseballTeamId == data?.homeTeamId
        : data?.SportId == 17
        ? item?.IceHockeyTeamId == data?.homeTeamId
        : data?.SportId == 6
        ? item?.BoxingTeamId == data?.homeTeamId
        : data?.SportId == 5
        ? item?.MMATeamId == data?.homeTeamId
        : data?.SportId == 8
        ? item?.SoccerTeamId == data?.homeTeamId
        : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId == 4
        ? item?.CricketTeamId == data?.awayTeamId
        : data?.SportId == 10
        ? item?.NBATeamId == data?.awayTeamId
        : data?.SportId == 15
        ? item?.AFLTeamId == data?.awayTeamId
        : data?.SportId == 9
        ? item?.ARTeamId == data?.awayTeamId
        : data?.SportId == 16
        ? item?.GolfTeamId == data?.awayTeamId
        : data?.SportId == 7
        ? item?.TennisTeamId == data?.awayTeamId
        : data?.SportId == 11
        ? item?.BaseballTeamId == data?.awayTeamId
        : data?.SportId == 17
        ? item?.IceHockeyTeamId == data?.awayTeamId
        : data?.SportId == 6
        ? item?.BoxingTeamId == data?.awayTeamId
        : data?.SportId == 5
        ? item?.MMATeamId == data?.awayTeamId
        : data?.SportId == 8
        ? item?.SoccerTeamId == data?.awayTeamId
        : item?.RLTeamId == data?.awayTeamId;
    });
    let OutrightsTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId == 4
        ? item?.CricketTeamId == teamid
        : data?.SportId == 10
        ? item?.NBATeamId == teamid
        : data?.SportId == 15
        ? item?.AFLTeamId == teamid
        : data?.SportId == 9
        ? item?.ARTeamId == teamid
        : data?.SportId == 16
        ? item?.GolfTeamId == teamid
        : data?.SportId == 7
        ? item?.TennisTeamId == teamid
        : data?.SportId == 11
        ? item?.BaseballTeamId == teamid
        : data?.SportId == 17
        ? item?.IceHockeyTeamId == teamid
        : data?.SportId == 6
        ? item?.BoxingTeamId == teamid
        : data?.SportId == 5
        ? item?.MMATeamId == teamid
        : data?.SportId == 8
        ? item?.SoccerTeamId == teamid
        : item?.RLTeamId == teamid;
    });

    let teamInfo =
      team === "hometeam"
        ? HomeTeamOdds
        : team === "awayteam"
        ? AwayTeamOdds
        : OutrightsTeamOdds;
    let teamData = teamInfo;
    // if (MarketName?.toLowerCase()?.includes("total")) {
    //   let OverData = allTeamOdds?.filter(ele => {
    //     let LabelName =
    //       data?.SportId == 4
    //         ? ele?.CricketOddLabel?.displayName
    //           ? ele?.CricketOddLabel?.displayName
    //           : ele?.CricketOddLabel?.name
    //         : data?.SportId == 10
    //         ? ele?.NBAOddLabel?.displayName
    //           ? ele?.NBAOddLabel?.displayName
    //           : ele?.NBAOddLabel?.name
    //         : data?.SportId == 15
    //         ? ele?.AFLOddLabel?.displayName
    //           ? ele?.AFLOddLabel?.displayName
    //           : ele?.AFLOddLabel?.name
    //         : data?.SportId == 9
    //         ? ele?.AROddLabel?.displayName
    //           ? ele?.AROddLabel?.displayName
    //           : ele?.AROddLabel?.name
    //         : data?.SportId == 16
    //         ? ele?.GolfOddLabel?.displayName
    //           ? ele?.GolfOddLabel?.displayName
    //           : ele?.GolfOddLabel?.name
    //         : data?.SportId == 7
    //         ? ele?.TennisOddLabel?.displayName
    //           ? ele?.TennisOddLabel?.displayName
    //           : ele?.TennisOddLabel?.name
    //         : data?.SportId == 11
    //         ? ele?.BaseballOddLabel?.displayName
    //           ? ele?.BaseballOddLabel?.displayName
    //           : ele?.BaseballOddLabel?.name
    //         : data?.SportId == 17
    //         ? ele?.IceHockeyOddLabel?.displayName
    //           ? ele?.IceHockeyOddLabel?.displayName
    //           : ele?.IceHockeyOddLabel?.name
    //         : data?.SportId == 6
    //         ? ele?.BoxingOddLabel?.displayName
    //           ? ele?.BoxingOddLabel?.displayName
    //           : ele?.BoxingOddLabel?.name
    //         : data?.SportId == 5
    //         ? ele?.MMAOddLabel?.displayName
    //           ? ele?.MMAOddLabel?.displayName
    //           : ele?.MMAOddLabel?.name
    //         : data?.SportId == 8
    //         ? ele?.SoccerOddLabel?.displayName
    //           ? ele?.SoccerOddLabel?.displayName
    //           : ele?.SoccerOddLabel?.name
    //         : ele?.RLOddLabel?.displayName
    //         ? ele?.RLOddLabel?.displayName
    //         : ele?.RLOddLabel?.name;
    //     let name = LabelName ? LabelName : ele?.label;
    //     return (
    //       name?.toLowerCase()?.includes("over") ||
    //       name?.toLowerCase()?.includes("totalover")
    //     );
    //   });
    //   let UnderData = allTeamOdds?.filter(ele => {
    //     let LabelName =
    //       data?.SportId == 4
    //         ? ele?.CricketOddLabel?.displayName
    //           ? ele?.CricketOddLabel?.displayName
    //           : ele?.CricketOddLabel?.name
    //         : data?.SportId == 10
    //         ? ele?.NBAOddLabel?.displayName
    //           ? ele?.NBAOddLabel?.displayName
    //           : ele?.NBAOddLabel?.name
    //         : data?.SportId == 15
    //         ? ele?.AFLOddLabel?.displayName
    //           ? ele?.AFLOddLabel?.displayName
    //           : ele?.AFLOddLabel?.name
    //         : data?.SportId == 9
    //         ? ele?.AROddLabel?.displayName
    //           ? ele?.AROddLabel?.displayName
    //           : ele?.AROddLabel?.name
    //         : data?.SportId == 16
    //         ? ele?.GolfOddLabel?.displayName
    //           ? ele?.GolfOddLabel?.displayName
    //           : ele?.GolfOddLabel?.name
    //         : data?.SportId == 7
    //         ? ele?.TennisOddLabel?.displayName
    //           ? ele?.TennisOddLabel?.displayName
    //           : ele?.TennisOddLabel?.name
    //         : data?.SportId == 11
    //         ? ele?.BaseballOddLabel?.displayName
    //           ? ele?.BaseballOddLabel?.displayName
    //           : ele?.BaseballOddLabel?.name
    //         : data?.SportId == 17
    //         ? ele?.IceHockeyOddLabel?.displayName
    //           ? ele?.IceHockeyOddLabel?.displayName
    //           : ele?.IceHockeyOddLabel?.name
    //         : data?.SportId == 6
    //         ? ele?.BoxingOddLabel?.displayName
    //           ? ele?.BoxingOddLabel?.displayName
    //           : ele?.BoxingOddLabel?.name
    //         : data?.SportId == 5
    //         ? ele?.MMAOddLabel?.displayName
    //           ? ele?.MMAOddLabel?.displayName
    //           : ele?.MMAOddLabel?.name
    //         : data?.SportId == 8
    //         ? ele?.SoccerOddLabel?.displayName
    //           ? ele?.SoccerOddLabel?.displayName
    //           : ele?.SoccerOddLabel?.name
    //         : ele?.RLOddLabel?.displayName
    //         ? ele?.RLOddLabel?.displayName
    //         : ele?.RLOddLabel?.name;
    //     let name = LabelName ? LabelName : ele?.label;
    //     return (
    //       name?.toLowerCase()?.includes("under") ||
    //       name?.toLowerCase()?.includes("totalunder")
    //     );
    //   });
    //   teamData = team === "over" ? OverData : UnderData;
    // }
    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);
    if (type === "odds") {
      if (teamData) {
        if (maxno !== -1) {
          if (maxno !== 0) {
            let bookkeeperid = teamData
              ?.map(obj => {
                if (obj?.odd === maxno) {
                  return obj?.BookKeeperId;
                }
              })
              ?.filter(x => x !== undefined);
            return fetchClickableOdds(
              maxno,
              bookkeeperid?.[0],
              "header",
              teamData,
              data,
              "betslip",
              Isscore,
              "currentbest"
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
    //  else if (type === "points") {
    //   if (maxno !== -1) {
    //     if (maxno !== 0) {
    //       let point = teamData
    //         ?.map(obj => {
    //           if (obj?.odd === maxno) {
    //             let LabelName =
    //               data?.SportId == 4
    //                 ? obj?.CricketOddLabel?.displayName
    //                   ? obj?.CricketOddLabel?.displayName
    //                   : obj?.CricketOddLabel?.name
    //                 : data?.SportId == 10
    //                 ? obj?.NBAOddLabel?.displayName
    //                   ? obj?.NBAOddLabel?.displayName
    //                   : obj?.NBAOddLabel?.name
    //                 : data?.SportId == 15
    //                 ? obj?.AFLOddLabel?.displayName
    //                   ? obj?.AFLOddLabel?.displayName
    //                   : obj?.AFLOddLabel?.name
    //                 : data?.SportId == 9
    //                 ? obj?.AROddLabel?.displayName
    //                   ? obj?.AROddLabel?.displayName
    //                   : obj?.AROddLabel?.name
    //                 : data?.SportId == 16
    //                 ? obj?.GolfOddLabel?.displayName
    //                   ? obj?.GolfOddLabel?.displayName
    //                   : obj?.GolfOddLabel?.name
    //                 : data?.SportId == 7
    //                 ? obj?.TennisOddLabel?.displayName
    //                   ? obj?.TennisOddLabel?.displayName
    //                   : obj?.TennisOddLabel?.name
    //                 : data?.SportId == 11
    //                 ? obj?.BaseballOddLabel?.displayName
    //                   ? obj?.BaseballOddLabel?.displayName
    //                   : obj?.BaseballOddLabel?.name
    //                 : data?.SportId == 17
    //                 ? obj?.IceHockeyOddLabel?.displayName
    //                   ? obj?.IceHockeyOddLabel?.displayName
    //                   : obj?.IceHockeyOddLabel?.name
    //                 : data?.SportId == 6
    //                 ? obj?.BoxingOddLabel?.displayName
    //                   ? obj?.BoxingOddLabel?.displayName
    //                   : obj?.BoxingOddLabel?.name
    //                 : data?.SportId == 5
    //                 ? obj?.MMAOddLabel?.displayName
    //                   ? obj?.MMAOddLabel?.displayName
    //                   : obj?.MMAOddLabel?.name
    //                 : data?.SportId == 8
    //                 ? obj?.SoccerOddLabel?.displayName
    //                   ? obj?.SoccerOddLabel?.displayName
    //                   : obj?.SoccerOddLabel?.name
    //                 : obj?.RLOddLabel?.displayName
    //                 ? obj?.RLOddLabel?.displayName
    //                 : obj?.RLOddLabel?.name;
    //             let name = LabelName ? LabelName : obj?.label;
    //             return obj?.point
    //               ? MarketName?.toLowerCase()?.includes("total")
    //                 ? name + " " + obj?.point
    //                 : obj?.point
    //               : "";
    //           }
    //         })
    //         ?.filter(x => x !== undefined);
    //       return point?.[0];
    //     } else {
    //       return "";
    //     }
    //   } else {
    //     return "";
    //   }
    // }
  };
  const fetchCurrentBestsOddsIcon = (data, type, team, teamid) => {
    let allTeamOdds =
      data?.SportId == 4
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : data?.SportId == 10
        ? data?.NBABetOffers?.[0]?.NBAOdds
        : data?.SportId == 15
        ? data?.AFLBetOffers?.[0]?.AFLOdds
        : data?.SportId == 9
        ? data?.ARBetOffers?.[0]?.AROdds
        : data?.SportId == 16
        ? data?.GolfBetOffers?.[0]?.GolfOdds
        : data?.SportId == 7
        ? data?.TennisBetOffers?.[0]?.TennisOdds
        : data?.SportId == 11
        ? data?.BaseballBetOffers?.[0]?.BaseballOdds
        : data?.SportId == 17
        ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
        : data?.SportId == 6
        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
        : data?.SportId == 5
        ? data?.MMABetOffers?.[0]?.MMAOdds
        : data?.SportId == 8
        ? data?.SoccerBetOffers?.[0]?.SoccerOdds
        : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId == 4
        ? item?.CricketTeamId == data?.homeTeamId
        : data?.SportId == 10
        ? item?.NBATeamId == data?.homeTeamId
        : data?.SportId == 15
        ? item?.AFLTeamId == data?.homeTeamId
        : data?.SportId == 9
        ? item?.ARTeamId == data?.homeTeamId
        : data?.SportId == 16
        ? item?.GolfTeamId == data?.homeTeamId
        : data?.SportId == 7
        ? item?.TennisTeamId == data?.homeTeamId
        : data?.SportId == 11
        ? item?.BaseballTeamId == data?.homeTeamId
        : data?.SportId == 17
        ? item?.IceHockeyTeamId == data?.homeTeamId
        : data?.SportId == 6
        ? item?.BoxingTeamId == data?.homeTeamId
        : data?.SportId == 5
        ? item?.MMATeamId == data?.homeTeamId
        : data?.SportId == 8
        ? item?.SoccerTeamId == data?.homeTeamId
        : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId == 4
        ? item?.CricketTeamId == data?.awayTeamId
        : data?.SportId == 10
        ? item?.NBATeamId == data?.awayTeamId
        : data?.SportId == 15
        ? item?.AFLTeamId == data?.awayTeamId
        : data?.SportId == 9
        ? item?.ARTeamId == data?.awayTeamId
        : data?.SportId == 16
        ? item?.GolfTeamId == data?.awayTeamId
        : data?.SportId == 7
        ? item?.TennisTeamId == data?.awayTeamId
        : data?.SportId == 11
        ? item?.BaseballTeamId == data?.awayTeamId
        : data?.SportId == 17
        ? item?.IceHockeyTeamId == data?.awayTeamId
        : data?.SportId == 6
        ? item?.BoxingTeamId == data?.awayTeamId
        : data?.SportId == 5
        ? item?.MMATeamId == data?.awayTeamId
        : data?.SportId == 8
        ? item?.SoccerTeamId == data?.awayTeamId
        : item?.RLTeamId == data?.awayTeamId;
    });
    let OutrightsTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId == 4
        ? item?.CricketTeamId == teamid
        : data?.SportId == 10
        ? item?.NBATeamId == teamid
        : data?.SportId == 15
        ? item?.AFLTeamId == teamid
        : data?.SportId == 9
        ? item?.ARTeamId == teamid
        : data?.SportId == 16
        ? item?.GolfTeamId == teamid
        : data?.SportId == 7
        ? item?.TennisTeamId == teamid
        : data?.SportId == 11
        ? item?.BaseballTeamId == teamid
        : data?.SportId == 17
        ? item?.IceHockeyTeamId == teamid
        : data?.SportId == 6
        ? item?.BoxingTeamId == teamid
        : data?.SportId == 5
        ? item?.MMATeamId == teamid
        : data?.SportId == 8
        ? item?.SoccerTeamId == teamid
        : item?.RLTeamId == teamid;
    });
    let teamInfo =
      team === "hometeam"
        ? HomeTeamOdds
        : team === "awayteam"
        ? AwayTeamOdds
        : OutrightsTeamOdds;
    let teamData = teamInfo;
    // if (MarketName?.toLowerCase()?.includes("total")) {
    //   let OverData = allTeamOdds?.filter(ele => {
    //     return ele?.label?.toLowerCase()?.includes("over");
    //   });
    //   let UnderData = allTeamOdds?.filter(ele => {
    //     return ele?.label?.toLowerCase()?.includes("under");
    //   });
    //   teamData = team === "over" ? OverData : UnderData;
    // }
    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno && maxno !== 0) {
        let providerid = teamData
          ?.map(obj => {
            let oddsType = obj?.odd;
            if (oddsType === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter(x => x !== undefined);

        return oddsicon(
          providerid?.[0],
          "header",
          teamData,
          data,
          "currentbest"
        );
      } else {
        return "";
        // <span className="odds"> - </span>
      }
    } else {
      return "";
      // <span className="odds"> - </span>
    }
  };
  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    Isscore,
    OddsType,
    teamType
  ) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(obj => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    const oddsInfo = BookKeeperData?.[0];
    let isAdded = BetslipData?.some(el => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      )
        return true;
      else return false;
    });
    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

    //   const Odds = Bet365Data?.filter(
    //     ele => ele?.providerMarketId && ele?.providerParticipantId
    //   );
    //   return (
    //     <a
    //       href={
    //         Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
    //           ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
    //           : `${iconData?.affiliate_link}`
    //       }
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       className="odds-link"
    //     >
    //       {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
    //     </a>
    //   );
    // } else {
    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      <>
        {odds || odds == 0 ? (
          OddsType == "currentbest" ? (
            <span
              className={`cursor-pointer current-best-odds-value  ${
                isAdded && IsBetslip === "betslip" ? "betslip-added" : ""
              } `}
              onClick={e => {
                IsBetslip === "nobetslip"
                  ? handleBookkeeperCounter(BookKeeperId, type, e)
                  : isAdded
                  ? handleDeleteBetslip(BookKeeperId, item, fulldata, e)
                  : handleAddToBetSlip(
                      BookKeeperId,
                      type,
                      item,
                      fulldata,
                      Isscore,
                      e
                    );
              }}
            >
              <span className="odds-link">
                {IsBetslip === "betslip" && !Isscore ? (
                  <Tooltip title="Bet" className="bet-tooltip" placement="top">
                    <span className="bet-now">
                      {" "}
                      {odds == 0 ? "SP" : Number(odds).toFixed(2)}{" "}
                    </span>
                  </Tooltip>
                ) : (
                  <>{odds == 0 ? "SP" : Number(odds).toFixed(2)}</>
                )}
              </span>
            </span>
          ) : (
            <>
              <Box className="odds-box-style">
                {/* {OddsType == "featuredOdds" && (
                  <Box className="odds-point">
                    {fetchSeeAllTeamOddsvalue(
                      item,
                      BookKeeperId,
                      teamType == "hometeam"
                        ? MarketName?.toLowerCase()?.includes("total")
                          ? "over"
                          : "hometeam"
                        : teamType == "awayteam"
                        ? MarketName?.toLowerCase()?.includes("total")
                          ? "under"
                          : "awayteam"
                        : null,
                      "spreadpoints"
                    )}
                  </Box>
                )} */}
                <Box
                  className={`odds-style  ${isAdded ? "active-odd-style" : ""}`}
                  onClick={e => {
                    IsBetslip === "nobetslip"
                      ? handleBookkeeperCounter(BookKeeperId, type, e)
                      : isAdded
                      ? handleDeleteBetslip(BookKeeperId, item, fulldata, e)
                      : handleAddToBetSlip(
                          BookKeeperId,
                          type,
                          item,
                          fulldata,
                          Isscore,
                          e
                        );
                  }}
                >
                  {IsBetslip === "betslip" && !Isscore ? (
                    <Tooltip
                      title="Bet"
                      className="bet-tooltip"
                      placement="top"
                    >
                      <Typography className="odds bet-now">
                        {" "}
                        {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
                  )}
                </Box>
                {/* {OddsType == "featuredOdds" &&
                  oddsicon(
                    BookKeeperId,
                    "header",
                    item,
                    fulldata,
                    "featuredOdds"
                  )} */}
                {/* <Box className="odd-img">
            <img
              src={
                item?.long_logo
                  ? Config.mediaURL + item?.long_logo
                  : DefaultImg
              }
              alt="bk"
            />
          </Box> */}
              </Box>
            </>
          )
        ) : null}
      </>
    );
  };
  const handleAddToBetSlip = async (
    BookKeeperId,
    type,
    item,
    fulldata,
    isScore,
    e
  ) => {
    e.stopPropagation();
    handleBookkeeperCounter(BookKeeperId, type, e);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    const oddsInfo = BookKeeperData?.[0];

    let passTeamId =
      data?.SportId == 4
        ? oddsInfo?.CricketTeamId
          ? oddsInfo?.CricketTeamId
          : oddsInfo?.CricketOddLabelId
          ? oddsInfo?.CricketOddLabelId
          : oddsInfo?.CricketPlayerId
        : data?.SportId == 10
        ? oddsInfo?.NBATeamId
          ? oddsInfo?.NBATeamId
          : oddsInfo?.NBAOddLabelId
          ? oddsInfo?.NBAOddLabelId
          : oddsInfo?.NBAPlayerId
        : data?.SportId == 15
        ? oddsInfo?.AFLTeamId
          ? oddsInfo?.AFLTeamId
          : oddsInfo?.AFLOddLabelId
          ? oddsInfo?.AFLOddLabelId
          : oddsInfo?.AFLPlayerId
        : data?.SportId == 9
        ? oddsInfo?.ARTeamId
          ? oddsInfo?.ARTeamId
          : oddsInfo?.AROddLabelId
          ? oddsInfo?.AROddLabelId
          : oddsInfo?.ARPlayerId
        : data?.SportId == 16
        ? oddsInfo?.GolfTeamId
          ? oddsInfo?.GolfTeamId
          : oddsInfo?.GolfOddLabelId
          ? oddsInfo?.GolfOddLabelId
          : oddsInfo?.GolfPlayerId
        : data?.SportId == 7
        ? oddsInfo?.TennisTeamId
          ? oddsInfo?.TennisTeamId
          : oddsInfo?.TennisOddLabelId
          ? oddsInfo?.TennisOddLabelId
          : oddsInfo?.TennisPlayerId
        : data?.SportId == 11
        ? oddsInfo?.BaseballTeamId
          ? oddsInfo?.BaseballTeamId
          : oddsInfo?.BaseballOddLabelId
          ? oddsInfo?.BaseballOddLabelId
          : oddsInfo?.BaseballPlayerId
        : data?.SportId == 17
        ? oddsInfo?.IceHockeyTeamId
          ? oddsInfo?.IceHockeyTeamId
          : oddsInfo?.IceHockeyOddLabelId
          ? oddsInfo?.IceHockeyOddLabelId
          : oddsInfo?.IceHockeyPlayerId
        : data?.SportId == 6
        ? oddsInfo?.BoxingTeamId
          ? oddsInfo?.BoxingTeamId
          : oddsInfo?.BoxingOddLabelId
          ? oddsInfo?.BoxingOddLabelId
          : oddsInfo?.BoxingPlayerId
        : data?.SportId == 5
        ? oddsInfo?.MMATeamId
          ? oddsInfo?.MMATeamId
          : oddsInfo?.MMAOddLabelId
          ? oddsInfo?.MMAOddLabelId
          : oddsInfo?.MMAPlayerId
        : data?.SportId == 8
        ? oddsInfo?.SoccerTeamId
          ? oddsInfo?.SoccerTeamId
          : oddsInfo?.SoccerOddLabelId
          ? oddsInfo?.SoccerOddLabelId
          : oddsInfo?.SoccerPlayerId
        : oddsInfo?.RLTeamId
        ? oddsInfo?.RLTeamId
        : oddsInfo?.RLOddLabelId
        ? oddsInfo?.RLOddLabelId
        : oddsInfo?.RLPlayerId;

    let payload = {
      sportId: fulldata?.SportId,
      eventId: fulldata?.id,
      teamId: passTeamId,
      bookKeeperId: BookKeeperId,
      betOfferId: oddsInfo?.id
    };
    if (isLogin) {
      if (!isScore) {
        try {
          const { status, data } = await axiosInstance.post(
            `betSlipCard/BetSlipCard`,
            payload
          );
          if (status === 200) {
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored"
            });

            const addedData = [...BetslipData, data?.result];
            dispatch(fetchBetSlipData(addedData));
            dispatch(fetchBetSlipCount(addedData?.length));
          } else {
          }
        } catch (err) {
          console.log("error", err);
        }
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
      if (!isScore) {
        setApiInfo({
          api: `betSlipCard/BetSlipCard`,
          payload: payload,
          method: "post"
        });
      }
    }
  };
  const handleDeleteBetslip = async (BookKeeperId, item, fulldata, e) => {
    e.stopPropagation();
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    const oddsInfo = BookKeeperData?.[0];
    let delslip = BetslipData?.filter(el => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        let chageData = BetslipData?.filter(obj => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };
  //   const fetchBookKeeper = async () => {
  //     try {
  //       const { status, data } = await axiosInstance.get(
  //         `public/apiProviders/bookkeeperproviders`
  //       );
  //       if (status === 200) {
  //         setBookKeeperData(data?.result);
  //       } else {
  //       }
  //     } catch (err) {}
  //   };

  const handleBookkeeperCounter = async (BookKeeperId, type, e) => {
    e.stopPropagation();
    let SportId = data?.SportId;
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(SportId)
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const handleAnchorTagClick = (e, url) => {
    window.open(url, "_blank");
    e.stopPropagation();
    // href={
    //   Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
    //     ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
    //     : `${iconData?.affiliate_link}`
    // }
  };

  return (
    <Box className="sports-odds-wrap  sports-current-best-odds-wrap">
      <span>
        {/* <Box className="points">
                                                        {fetchCurrentBestOdds(
                                                          item,
                                                          "points",
                                                          MarketName?.toLowerCase()?.includes(
                                                            "total"
                                                          )
                                                            ? "over"
                                                            : "awayteam",
                                                          null,
                                                          true
                                                        )}
                                                      </Box> */}
        <span>{fetchCurrentBestOdds(data, type, team, teamId, isScore)}</span>
      </span>
      {fetchCurrentBestsOddsIcon(data, type, team)}
    </Box>
  );
};

export default SportsOdds;
