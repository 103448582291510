import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "src/helpers/context";
import DefaultImg from "src/assets/images/smartb_default.png";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { fetchFromStorage } from "src/helpers/context";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import "./raceOdds.scss";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";

const RaceOdds = ({ data, sportId, isResult, raceId }) => {
  const raceTrackdata = [];
  const dispatch = useDispatch();
  const { setApiInfo } = useAutoSaveStore();
  const BookkeeperData = useSelector(state => state?.reduxData?.BookkeeperData);
  const BetslipData = useSelector(state => state?.reduxData?.BetSlipData);
  const handleBookkeeperCounter = async (BookKeeperId, type, e) => {
    e.stopPropagation();
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(sportId)
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const oddsicon = (BookKeeperId, type, item, oddsType) => {
    const newData = raceTrackdata?.ApiRaceIdentifiers?.map(obj => {
      const BookkeeperObj = obj?.provider?.BookKeeperProviders?.filter(item => {
        return item?.BookKeeperId === BookKeeperId;
      });
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null
      };
    });

    const filteredData = newData?.filter?.(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(obj => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    let imageUrl =
      oddsType == "currentbest"
        ? iconData?.currentBest_logo
        : iconData?.long_logo;
    if (item && BookKeeperId === 8) {
      const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
      return Bet365Data && Bet365Data?.length > 0 ? (
        <a
          href={
            Odds?.providerMarketId && Odds?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          {oddsType == "currentbest" ? (
            <img
              className="currentbest-bookmaker-thumb"
              src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
              alt="Odds Icon"
              onClick={e => handleBookkeeperCounter(BookKeeperId, type, e)}
            />
          ) : (
            <Box className="odd-img">
              <img
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                alt="Odds Icon"
                onClick={e => handleBookkeeperCounter(BookKeeperId, type, e)}
              />
            </Box>
          )}
        </a>
      ) : (
        <></>
      );
    } else if (BookKeeperId == 13) {
      const fetchUrlArray = filteredData?.[0]?.url?.split("/");
      const fetchMarketId = fetchUrlArray?.[fetchUrlArray?.length - 1];
      const isMobile = () => {
        const userAgent = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        );
      };
      const betFairUrl = isMobile()
        ? filteredData?.[0]?.url
          ? filteredData?.[0]?.url + `?Referrer=SmartB`
          : iconData?.affiliate_link
        : filteredData?.[0]?.url
        ? `https://www.betfair.com.au/exchange/plus/${
            sportId == 3 ? "greyhound-racing" : "horse-racing"
          }/market/${fetchMarketId}`
        : iconData?.affiliate_link;
      return (
        <Box className="racing-current-best-odds-icon">
          <a href={betFairUrl} target="_blank" rel="noopener noreferrer">
            {oddsType == "currentbest" ? (
              <img
                className="currentbest-bookmaker-thumb"
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                alt="Odds Icon"
                onClick={e => handleBookkeeperCounter(BookKeeperId, type, e)}
              />
            ) : (
              <Box className="odd-img">
                <img
                  src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                  alt="Odds Icon"
                  onClick={e => handleBookkeeperCounter(BookKeeperId, type, e)}
                />
              </Box>
            )}
          </a>
        </Box>
      );
    } else {
      return (
        <Box className="racing-current-best-odds-icon">
          <a
            href={
              filteredData?.length > 0 && filteredData?.[0]?.url
                ? filteredData?.[0]?.url + `?Referrer=SmartB`
                : iconData?.affiliate_link
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {oddsType == "currentbest" ? (
              <img
                className="currentbest-bookmaker-thumb"
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                alt="Odds Icon"
                onClick={e => handleBookkeeperCounter(BookKeeperId, type, e)}
              />
            ) : (
              <Box className="odd-img">
                <img
                  src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                  alt="Odds Icon"
                  onClick={e => handleBookkeeperCounter(BookKeeperId, type, e)}
                />
              </Box>
            )}
          </a>
        </Box>
      );
    }
  };
  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    oddsType
  ) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;

    let isAdded = BetslipData?.some(el => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId ==
          BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId &&
        el?.eventId == Number(raceId)
      )
        return true;
      else return false;
    });
    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8)

    //   const Odds = Bet365Data?.[0]?.RaceOdds?.[0]
    //   return (
    //     Bet365Data && Bet365Data?.length > 0 ?
    //       (<a href={Odds?.providerMarketId && Odds?.providerParticipantId ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId
    //         }~${Odds?.intValue}~1&bet=1` : `${iconData?.affiliate_link}`} target="_blank" rel="noopener noreferrer" className="odds-link" >  {odds === "SP" ? "SP" : Number(odds).toFixed(2)} </a>
    //       ) : (<></>)
    //   )
    // } else {

    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      oddsType == "currentbest" ? (
        <Box
          className={`racing-current-best-odds-value ${
            isAdded ? "active-odd-style" : ""
          }`}
          onClick={e => {
            e.stopPropagation();
            IsBetslip === "nobetslip"
              ? handleBookkeeperCounter(BookKeeperId, type, e)
              : isAdded
              ? handleDeleteBetslip(BookKeeperId, item, e)
              : handleAddToBetSlip(BookKeeperId, type, item, fulldata, e);
          }}
        >
          <span
            className={
              isAdded && IsBetslip === "betslip"
                ? "betslip-added odds-link"
                : "odds-link"
            }
          >
            {IsBetslip === "betslip" && !isResult ? (
              <Tooltip title="Bet" className="bet-tooltip" placement="top">
                <span className="bet-now">
                  {" "}
                  {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
                </span>
              </Tooltip>
            ) : (
              <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
            )}
          </span>
        </Box>
      ) : (
        <Box
          className={`odds-style  ${isAdded ? "active-odd-style" : ""}`}
          onClick={e => {
            e.stopPropagation();
            IsBetslip === "nobetslip"
              ? handleBookkeeperCounter(BookKeeperId, type, e)
              : isAdded
              ? handleDeleteBetslip(BookKeeperId, item, e)
              : handleAddToBetSlip(BookKeeperId, type, item, fulldata, e);
          }}
        >
          {IsBetslip === "betslip" && !isResult ? (
            <Tooltip title="Bet" className="bet-tooltip" placement="top">
              <Typography className="odds bet-now">
                {" "}
                {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
              </Typography>
            </Tooltip>
          ) : (
            <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
          )}
        </Box>
      )
      // </a>
    );
    // }
  };
  const handleAddToBetSlip = async (BookKeeperId, type, item, fulldata, e) => {
    e.stopPropagation();
    handleBookkeeperCounter(BookKeeperId, type, e);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let payload = {
      sportId: Number(sportId),
      eventId: Number(raceId),
      teamId: BookKeeperData?.[0]?.RacingParticipantId,
      bookKeeperId: BookKeeperId,
      betOfferId: BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId
    };
    if (isLogin) {
      if (!isResult) {
        try {
          const { status, data } = await axiosInstance.post(
            `betSlipCard/BetSlipCard`,
            payload
          );
          if (status === 200) {
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored"
            });
            const addedData = [...BetslipData, data?.result];
            dispatch(fetchBetSlipData(addedData));
            dispatch(fetchBetSlipCount(addedData?.length));
          } else {
          }
        } catch (err) {
          console.log("error", err);
        }
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
      if (!isResult) {
        setApiInfo({
          api: `betSlipCard/BetSlipCard`,
          payload: payload,
          method: "post"
        });
      }
    }
  };
  const handleDeleteBetslip = async (BookKeeperId, item, e) => {
    e.stopPropagation();
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let delslip = BetslipData?.filter(el => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId ==
          BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId &&
        el?.eventId == Number(raceId)
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        let chageData = BetslipData?.filter(obj => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };
  const fetchCurrentBestValue = (data, fulldata) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map(obj => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter(x => x !== undefined);
        return fetchClickableOdds(
          maxno,
          providerid?.[0],
          "header",
          data,
          fulldata,
          "betslip",
          "currentbest"
        );
      } else {
        return <span className="sp-odds">SP</span>;
      }
    } else {
      return <span className="sp-odds">SP</span>;
    }
  };
  const fetchCurrentBestIcon = data => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map(obj => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter(x => x !== undefined);
        return oddsicon(providerid?.[0], "header", data, "currentbest");
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  return (
    <>
      <Box className={`racing-current-best-odds-wrap`}>
        {fetchCurrentBestValue(data)}
        {/* </Box> */}
        {/* <Box className="current-best-odds-icon"> */}
        {fetchCurrentBestIcon(data)}
        {/* </Box> */}
      </Box>
    </>
  );
};

export default RaceOdds;
