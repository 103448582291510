import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Tabs,
  Tab,
  Collapse,
  Grid,
  Button,
} from "@material-ui/core";
import RaceCountdown from "./raceCountdown";
import OurPartner from "src/views/component/ourPartners";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { IntlContext } from "src/App";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import Select from "react-select";
import banner from "../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import Weather from "../../../../../assets/images/icons/weather/w-good.svg";
import Cloudy from "../../../../../assets/images/icons/weather/cloudy.svg";
import Rain from "../../../../../assets/images/icons/weather/rain.svg";
import Showers from "../../../../../assets/images/icons/weather/showers.svg";
import { ReactComponent as BlackBookIcon } from "../../../../../assets/images/icons/blackbookicon.svg";
import AdvertisingBannner from "src/views/component/advertisingBanner";
// import { RunnerTable } from "./runnerTable";
import { RunnerTable } from "./runnerTableV2";
import RunnerResultTable from "./RunnerResultTable";
import Loader from "src/components/Loader";
import { Routes } from "../../../../../helpers/constants/routeConstants";
import { Config } from "../../../../../helpers/context/config";
import "./runnerTable.scss";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import AboveRunnerTable from "../../../../../assets/images/ad-placeholder/sports1.webp";
import AboveOurPartner from "../../../../../assets/images/ad-placeholder/sports2.webp";
import BelowOurPartner from "../../../../../assets/images/ad-placeholder/sports5.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ScriptLoader from "src/views/component/scriptLoader/ScriptLoader";
import BlackBookModal from "src/views/component/UI/blackBookModal";
import { fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";

// Clent's current Timezone
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const RunnersTablePage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const [selectedOption, setSelectedOption] = useState(
    state?.CurrentData?.track?.id
  );
  const [raceTrackListData, setraceTrackListData] = useState([]);
  const [raceTrackdata, setraceTrackdata] = useState({});
  const [runnerData, setRunnerData] = useState([]);

  const [eventData, seteventData] = useState([]);
  const [eventFullList, setEventFullList] = useState([]);
  // const [selectedRaceData, setselectedRaceData] = useState({});

  const [isEventLoading, setisEventLoading] = useState(false);
  const [isrunnnerLoading, setisrunnnerLoading] = useState(false);

  const [selectedDate, setselectedDate] = useState();
  const [trackListData, settrackListData] = useState([]);
  const [selectedTrack, setselectedTrack] = useState("");
  const [selectedEvent, setselectedEvent] = useState("");
  const [selectedRaceId, setselectedRaceId] = useState("");
  const [raceTrackClicked, setraceTrackClicked] = useState(false);
  const [eventLocation, setEventLocation] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isResult, setIsResult] = useState(false);
  const [RaceResultData, setRaceResultData] = useState({});
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [CommentsOpen, setCommentsOpen] = useState(-1);
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [isIntl, setIsIntl] = useState(null);

  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleBlackBookModal = (title, id, type) => {
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedId(id);
    handleModal();
  };
  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  // handle changes of track
  const handleChangeTrack = (e, newValue) => {
    let head_date = selectedDate
      ? selectedDate
      : new URLSearchParams(window.location.search).get("date");
    let isIntlTrack =
      isIntl !== null
        ? isIntl
        : new URLSearchParams(window.location.search).get("intl");

    navigate(
      Routes.RunnerDetails(
        params?.race,
        params?.sportId,
        newValue,
        selectedTrack,
        selectedEvent,
        head_date,
        isIntlTrack
      )
    );

    setselectedRaceId(newValue);
    // fetchRaceRunner(newValue);
  };
  const fetchRaceRunner = async (id) => {
    setisrunnnerLoading(true);
    try {
      const { status, data } = await axiosInstance.get(`/events/runner/${id}`);
      if (status === 200) {
        setraceTrackdata(data?.data?.race);
        setRunnerData(data?.data?.runners);
        setisrunnnerLoading(false);

        let isRaceTrackClicked = params?.raceId !== undefined ? true : false;
        if (isRaceTrackClicked) {
          fetchTrackList(data?.data?.race?.startTimeDate);
        }
        setraceTrackClicked(isRaceTrackClicked);
        if (
          data?.data?.race?.startTimeDate &&
          data?.data?.race?.startTimeDate !== null
        ) {
          setselectedDate(data?.data?.race?.startTimeDate);
        }
        let RunnerExtraInfoData = data?.data?.runners?.map((item) => {
          return item?.RunnerInfos?.[0];
        });

        let finalData = RunnerExtraInfoData?.map((item) => {
          return JSON.parse(item?.info);
        });
        // let runnerExtraInfoDatas =JSON.parse(finalData)
      }
    } catch (err) {
      setisrunnnerLoading(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    fetchAdsData();
  }, []);
  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);
  // useEffect(() => {
  //   console.log("parmss", params);

  //   // check race track is clicked or not by using route (raceID)
  //   let isRaceTrackClicked = params?.raceId !== undefined ? true : false;
  //   if (isRaceTrackClicked) {
  //     fetchTrackList();
  //   }

  //   // setselectedTrack(
  //   //   params?.trackId !== undefined && JSON.parse(params?.trackId)
  //   // );
  //   setselectedEvent(
  //     params?.eventId !== undefined && JSON.parse(params?.eventId)
  //   );
  //   setselectedRaceId(
  //     params?.raceId !== undefined && JSON.parse(params?.raceId)
  //   );

  // }, []);

  useEffect(() => {
    // let head_date = new URLSearchParams(window.location.search).get('date');

    // if selected date and Route's date not match then set route date as selected date
    // if (head_date !== null) {
    //   setselectedDate(head_date);
    // }

    // check race track is clicked or not by using route (raceID)
    // let isRaceTrackClicked = params?.raceId !== undefined ? true : false;
    // if (isRaceTrackClicked) {
    //   fetchTrackList();
    // }
    // setraceTrackClicked(isRaceTrackClicked);

    let SelectedRacing = state?.raceData?.filter((item) => {
      return item?.track?.id == selectedOption;
    });

    setselectedTrack(
      params?.trackId !== undefined && JSON.parse(params?.trackId)
    );
    setselectedEvent(
      params?.eventId !== undefined && JSON.parse(params?.eventId)
    );
    setselectedRaceId(
      params?.raceId !== undefined && JSON.parse(params?.raceId)
    );
    fetchRaceRunner(params?.raceId !== undefined && JSON.parse(params?.raceId));
  }, [params]);

  useEffect(() => {
    if (eventFullList?.length > 0) {
      let selectedEvent = eventFullList?.filter(
        (event) => event?.id === Number(params?.eventId)
      );
      let selectedRace = selectedEvent?.[0]?.race?.filter(
        (race) => race?.id === Number(params?.raceId)
      );

      let resultData = selectedRace?.[0]?.RaceResultSummary;
      setIsResult(resultData ? true : false);
      setRaceResultData(resultData);
    }
  }, [params, eventData]);

  const handleEvent = (e) => {
    // setselectedTrack(e?.value);
    let selected_obj = trackListData?.find((obj) => obj?.id === e?.value);

    let haveRace = selected_obj?.race?.filter(
      (data) => data?.startTimeDate !== null
    );
    let upnextRaces = selected_obj?.race?.filter(
      (item) =>
        item?.startTimeDate !== null &&
        moment(new Date()).isBefore(new Date(item?.startTimeDate))
    );

    let RaceId = upnextRaces?.length > 0 ? upnextRaces[0].id : haveRace[0].id;

    setselectedEvent(e?.value);
    let head_date = selectedDate
      ? selectedDate
      : new URLSearchParams(window.location.search).get("date");
    let isIntlTrack =
      isIntl !== null
        ? isIntl
        : new URLSearchParams(window.location.search).get("intl");
    setselectedRaceId(RaceId);
    // fetchRaceRunner(RaceId);

    navigate(
      Routes.RunnerDetails(
        params?.race,
        params?.sportId,
        RaceId,
        selected_obj?.trackId,
        e?.value,
        head_date,
        isIntl
      )
    );
  };

  useEffect(() => {
    let eventData = trackListData?.filter((item) => {
      return item?.id == selectedEvent;
    });
    setEventLocation(eventData);
  }, [trackListData, selectedEvent]);
  //  let haveRace = selectedRaceData?.[0]?.race?.filter(
  //   (data) =>
  //     data?.startTimeDate !== null
  // );
  // setselectedRaceId(haveRace?.[0]?.id)

  // Fetch Tracklist Data
  const fetchTrackList = async (date) => {
    setisEventLoading(true);
    let head_date = date
      ? moment(date).format("YYYY-MM-DD")
      : new URLSearchParams(window.location.search).get("date");
    let date_to_pass =
      head_date !== null
        ? head_date
        : selectedDate !== ""
          ? moment(selectedDate)
            .tz(moment(), timezone)
            .format("YYYY-MM-DD")
          : moment(new Date())
            .tz(moment(), timezone)
            .format("YYYY-MM-DD");
    let id = params.sportId;
    let meetingState =
      isIntl !== null
        ? isIntl
        : new URLSearchParams(window.location.search).get("intl");

    let meetingStateValue = meetingState == "true" ? "Intl" : "Aus/NZ,Intl";

    try {
      const { status, data } = await axiosInstance.get(
        `events/trackList/?sportId=${id}&MeetingState=${meetingStateValue}&todate=${date_to_pass}&countryId=${""}&stateId=${""}&timezone=${timezone}`
      );
      if (status === 200) {
        let data_pass =
          data?.events?.length > 0
            ? data?.events?.map((obj) => {
              // remove raceNumber === 0 and duplicate raceNumber's races from tracklist
              return {
                ...obj,
                race: Array.from(new Set(obj?.race.map((a) => a?.raceNumber)))
                  .map((id) => {
                    return obj?.race.find((a) => a?.raceNumber === id);
                  })
                  .filter((race) => race?.raceNumber != 0),
              };
            })
            : [];
        setEventFullList(data_pass);
        let sportId =
          data_pass?.length > 0
            ? data_pass[0]?.sportId
            : Number(params.sportId);

        let race =
          sportId === 1 ? "horse" : sportId === 2 ? "harness" : "greyhounds";

        // Race details change that time call
        // if (isRunnersChange) {
        //   settrackListData(data_pass);
        //   setselectedTrack(
        //     data_pass?.length > 0 ? data_pass[0]?.trackId : null
        //   );
        //   setraceTrackListData(data_pass?.length > 0 ? data_pass[0]?.race : []);
        //   selectedRaceId(
        //     data_pass?.length > 0
        //       ? data_pass[0]?.race?.length > 0
        //         ? data_pass[0]?.race[0]?.id !== undefined
        //           ? data_pass[0]?.race[0]?.id
        //           : null
        //         : null
        //       : null
        //   );
        //   const newData = [];
        //   let track = data_pass?.map((item) => {
        //     newData.push({
        //       label: item?.eventName + ',' + item?.state?.stateCode,
        //       value: item?.id,
        //     });
        //   });
        //   seteventData(newData);
        //   setisEventLoading(false);
        // } else {
        let all_data = data_pass;

        // find selected races from tracklist data
        let selected_track_races = all_data?.find(
          (obj) => obj?.id === Number(params?.eventId)
        );
        const isInterNational =
          selected_track_races?.country?.id == 13 ||
            selected_track_races?.country?.id == 157
            ? false
            : true;
        settrackListData(data_pass);
        setraceTrackListData(selected_track_races?.race);
        setIsIntl(isInterNational);

        const newData = [];
        let track = data_pass?.map((item) => {
          newData.push({
            label: item?.state?.stateCode
              ? item?.eventName + "," + item?.state?.stateCode
              : item?.eventName,
            value: item?.id,
          });
        });
        seteventData(newData);
        setisEventLoading(false);
        // }
      }
    } catch (err) {
      setisEventLoading(false);
    }
  };

  const WeatherIcon = (type) => {
    if (type === "showers") {
      return Showers;
    } else if (type === "Rain") {
      return Rain;
    } else if (type === "fine") {
      return Weather;
    } else if (type === "FINE") {
      return Weather;
    } else if (type === "Good") {
      return Weather;
    } else if (type === "Cloudy") {
      return Cloudy;
    } else {
      return Weather;
    }
  };
  // const handleAdsImression = async ids => {
  //   try {
  //     const payload = {
  //       ids: ids
  //     };
  //     const { status, data } = await axiosInstance.post(
  //       `addImpression`,
  //       payload
  //     );
  //     if (status === 200) {
  //     }
  //   } catch (err) { }
  // };

  const fetchAdsData = async () => {
    try {
      let pageId = params.sportId == 1 ? 3 : params.sportId == 3 ? 4 : 5;
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${pageId}&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) { }
  };
  const fetchAds = (position, height, placeholder) => {
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  let currentDate = moment().format("YYYY-MM-DD");
  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;
  return (
    <>
      {/* <AdvertisingBannner src={banner} /> */}
      {/* {fetchAds(1, "218px", banner)} */}
      {/* {fetchPageHeaderAds("218px", banner)} */}
      <ScriptLoader scriptCode={scriptCode} />
      <Box className="banner-img-fix">
        {fetchPageHeaderAds("218px", banner)}
      </Box>
      {isEventLoading ? (
        <div className="allsport-loader-center">
          <Loader />
        </div>
      ) : (
        <Box className="allracing-wrap ">
          <Box className="side-space">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  {localesData?.MENU?.HOME}
                </Link>
                <Link underline="hover" color="inherit" to="/racing">
                  {localesData?.MENU?.RACING}
                </Link>
                <Link
                  to={
                    params.sportId == 1
                      ? "/racing?type=1"
                      : params.sportId == 3
                        ? "/racing?type=3"
                        : params.sportId == 2
                          ? "/racing?type=2"
                          : ""
                  }
                >
                  {params.sportId == 1
                    ? "Horse Racing"
                    : params.sportId == 3
                      ? "Greyhound Racing"
                      : params.sportId == 2
                        ? "Harness Racing"
                        : ""}
                </Link>

                <Typography>{eventLocation[0]?.eventName}</Typography>
                <Typography>
                  {
                    eventLocation?.[0]?.race?.filter((item) => {
                      return item?.id == selectedRaceId;
                    })?.[0]?.raceNumber
                  }
                </Typography>
              </Breadcrumbs>
            </Box>
          </Box>
          {isEventLoading ? (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          ) : screenWidth > 799 ? (
            <Box className="sport-tab">
              <Box className="racing-tab">
                {/* Racing DropDown */}
                <Box className="select-wrap">
                  <Select
                    className="React"
                    value={eventData?.find((item) => {
                      return item?.value == selectedEvent;
                    })}
                    onChange={(e) => handleEvent(e)}
                    options={eventData}
                    classNamePrefix="select"
                    isSearchable={false}
                  />
                </Box>
                {/* Racing Location */}
                {eventLocation?.length > 0 ? (
                  <Box className="racing-location">
                    <ul>
                      {eventLocation[0]?.country ? (
                        <li>
                          {eventLocation[0]?.country?.country_flag?.includes(
                            "uploads"
                          ) ? (
                            <img
                              src={
                                Config.mediaURL +
                                eventLocation[0]?.country?.country_flag
                              }
                              width="22px"
                              alt="Race Country"
                            />
                          ) : (
                            <img
                              src={eventLocation[0]?.country?.country_flag}
                              width="22px"
                              alt="Race Country"
                            />
                          )}

                          <span className="country-name">
                            {eventLocation[0]?.country?.country}
                          </span>
                        </li>
                      ) : (
                        ""
                      )}
                      {eventLocation[0]?.state ? (
                        <li>
                          <span>{eventLocation[0]?.state?.state}</span>
                        </li>
                      ) : (
                        ""
                      )}
                      <li>
                        <span>{eventLocation[0]?.eventName}</span>
                      </li>
                    </ul>
                  </Box>
                ) : (
                  ""
                )}

                {/* Racing Track */}
                <Box className="racing-track">
                  <span>{localesData?.RUNNER_TABLE?.TRACK}:</span>
                  {/* <Rating name="customized-10" value={5} readOnly /> */}
                  <span>
                    {raceTrackdata?.trackCondition?.replace(/"/g, "")}
                  </span>
                </Box>
                {/* Racing Wether */}
                <Box className="racing-Weather">
                  <span>{localesData?.RUNNER_TABLE?.WEATHER}:</span>
                  <Box className="weather">
                    {raceTrackdata?.Weather &&
                      raceTrackdata?.Weather?.weatherType ? (
                      <>
                        <img
                          src={WeatherIcon(raceTrackdata?.Weather?.weatherType)}
                          alt="weather"
                        />
                        <span>{raceTrackdata?.Weather?.weatherType}</span>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Box>

              {/* Tracklist TabBar */}
              <Box className="race-track-blackbook">
                <Box className="race-track-list">
                  <Tabs
                    value={selectedRaceId}
                    onChange={handleChangeTrack}
                    className="race-track"
                  >
                    {eventLocation[0]?.race.length > 0 &&
                      eventLocation[0]?.race?.map((obj, i) => (
                        <Tab
                          disableRipple
                          disableFocusRipple
                          label={obj?.raceNumber}
                          key={i}
                          value={obj?.id}
                          disabled={obj?.id === undefined ? true : false}
                        />
                      ))}
                  </Tabs>
                </Box>
                <Box className="blackbook-button-wrap">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="blackbook-button"
                    onClick={() =>
                      handleBlackBookModal(
                        eventLocation?.[0]?.track?.name,
                        eventLocation?.[0]?.track?.id,
                        "Track"
                      )
                    }
                  >
                    <span style={{ display: "flex" }}>
                      <BlackBookIcon />{" "}
                    </span>{" "}
                    <span style={{ paddingLeft: "8px" }}>
                      {" "}
                      {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}{" "}
                    </span>
                  </Button>
                </Box>
              </Box>
              {/* Racing Detail */}

              <Box className="racing-detail-head">
                <Box>
                  <h6>{raceTrackdata?.raceName}</h6>
                </Box>
                <Box className="race-track-info-right">
                  <span>{localesData?.RUNNER_TABLE?.DISTANCE}: </span>
                  <span>
                    {raceTrackdata?.Distance?.name}
                    {"m"}
                  </span>
                </Box>
                <Box>
                  <span>{localesData?.RUNNER_TABLE?.JUMP_TIME}: </span>
                  <span>
                    {moment(currentDate).isSame(
                      moment(raceTrackdata?.startTimeDate).format("YYYY-MM-DD")
                    )
                      ? moment
                        .utc(raceTrackdata?.startTimeDate)
                        .local()
                        .format("hh:mm a")
                      : moment
                        .utc(raceTrackdata?.startTimeDate)
                        .local()
                        .format("YYYY/MM/DD hh:mm a")}
                  </span>
                </Box>
                <Box className="race-track-info-right">
                  <span>{localesData?.RUNNER_TABLE?.TIME_TILL_JUMP}: </span>
                  <span>
                    <RaceCountdown
                      expiryTimestamp={new Date(
                        raceTrackdata?.startTimeDate
                      ).getTime()}
                    />
                  </span>
                </Box>
              </Box>

              {/* Racing Comment */}
              <Box className="race-comment">
                <Box
                  className="race-comment-box"
                  onClick={() => setCommentsOpen(CommentsOpen === 0 ? -1 : 0)}
                >
                  <h6>{localesData?.RUNNER_TABLE?.RACE_COMMENTS}</h6>
                  <IconButton aria-label="expand row" size="small">
                    {CommentsOpen === 0 ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </Box>
                <Collapse in={CommentsOpen === 0} timeout="auto" unmountOnExit>
                  <Typography variant="body2">
                    {raceTrackdata?.comment == "{}"
                      ? "No Comment Available"
                      : raceTrackdata?.comment
                        ? raceTrackdata?.comment
                        : "No Comment Available"}
                  </Typography>
                </Collapse>
              </Box>
            </Box>
          ) : (
            <Box className="sport-tab">
              <Box className="racing-tab">
                {/* Racing DropDown */}
                <Box className="select-wrap">
                  <Select
                    className="React"
                    value={eventData?.find((item) => {
                      return item?.value == selectedEvent;
                    })}
                    onChange={(e) => handleEvent(e)}
                    options={eventData}
                    classNamePrefix="select"
                    isSearchable={false}
                  />
                </Box>

                {/* Tracklist TabBar */}

                <Box className="race-track-list">
                  <Tabs
                    value={selectedRaceId}
                    onChange={handleChangeTrack}
                    className="race-track"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {eventLocation[0]?.race.length > 0 &&
                      eventLocation[0]?.race?.map((obj, i) => (
                        <Tab
                          disableRipple
                          disableFocusRipple
                          label={obj?.raceNumber}
                          key={i}
                          value={obj?.id}
                          disabled={obj?.id === undefined ? true : false}
                        />
                      ))}
                  </Tabs>
                </Box>
                {/* Racing Location */}
                {eventLocation?.length > 0 ? (
                  <Box className="racing-location">
                    <ul>
                      {eventLocation[0]?.country ? (
                        <li>
                          {eventLocation[0]?.country?.country_flag?.includes(
                            "uploads"
                          ) ? (
                            <img
                              src={
                                Config.mediaURL +
                                eventLocation[0]?.country?.country_flag
                              }
                              width="22px"
                              alt="Race Country"
                            />
                          ) : (
                            <img
                              src={eventLocation[0]?.country?.country_flag}
                              width="22px"
                              alt="Race Country"
                            />
                          )}
                          <span className="country-name">
                            {eventLocation[0]?.country?.country}
                          </span>
                        </li>
                      ) : (
                        ""
                      )}
                      {eventLocation[0]?.state ? (
                        <li>
                          <span>{eventLocation[0]?.state?.state}</span>
                        </li>
                      ) : (
                        ""
                      )}
                      <li>
                        <span>{eventLocation[0]?.eventName}</span>
                      </li>
                    </ul>
                  </Box>
                ) : (
                  ""
                )}

                {/* Racing Track */}
                <Box className="track-weather-wrap">
                  <Box className="racing-track">
                    <span>{localesData?.RUNNER_TABLE?.TRACK}:</span>
                    {/* <Rating name="customized-10" value={5} readOnly /> */}
                    <span>
                      {raceTrackdata?.trackCondition?.replace(/"/g, "")}
                    </span>
                  </Box>
                  {/* Racing Wether */}
                  <Box className="racing-Weather">
                    <span>{localesData?.RUNNER_TABLE?.WEATHER}:</span>
                    <Box className="weather">
                      {raceTrackdata?.Weather &&
                        raceTrackdata?.Weather?.weatherType ? (
                        <>
                          <img
                            src={WeatherIcon(
                              raceTrackdata?.Weather?.weatherType
                            )}
                            alt="weather"
                          />
                          <span>{raceTrackdata?.Weather?.weatherType}</span>{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Racing Detail */}

              <Box className="racing-detail-head">
                <Box>
                  <h6>{raceTrackdata?.raceName}</h6>
                </Box>
                <Box className="race-track-info-right">
                  <span>{localesData?.RUNNER_TABLE?.DISTANCE}: </span>
                  <span>
                    {raceTrackdata?.Distance?.name}
                    {"m"}
                  </span>
                </Box>
                <Box>
                  <span>{localesData?.RUNNER_TABLE?.JUMP_TIME}: </span>
                  <span>
                    {moment(currentDate).isSame(
                      moment(raceTrackdata?.startTimeDate).format("YYYY-MM-DD")
                    )
                      ? moment
                        .utc(raceTrackdata?.startTimeDate)
                        .local()
                        .format("hh:mm a")
                      : moment
                        .utc(raceTrackdata?.startTimeDate)
                        .local()
                        .format("YYYY/MM/DD hh:mm a")}
                  </span>
                </Box>
                <Box className="race-track-info-right">
                  <span>{localesData?.RUNNER_TABLE?.TIME_TILL_JUMP}: </span>
                  <span>
                    <RaceCountdown
                      expiryTimestamp={new Date(
                        raceTrackdata?.startTimeDate
                      ).getTime()}
                    />
                  </span>
                </Box>
              </Box>

              {/* Racing Comment */}
              <Box className="race-comment">
                <Box
                  className="race-comment-box"
                  onClick={() => setCommentsOpen(CommentsOpen === 0 ? -1 : 0)}
                >
                  <h6>{localesData?.RUNNER_TABLE?.RACE_COMMENTS}</h6>
                  <IconButton aria-label="expand row" size="small">
                    {CommentsOpen === 0 ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </Box>

                <Collapse in={CommentsOpen === 0} timeout="auto" unmountOnExit>
                  <Typography variant="body2">
                    {raceTrackdata?.comment == "{}"
                      ? "No Comment Available"
                      : raceTrackdata?.comment
                        ? raceTrackdata?.comment
                        : "No Comment Available"}
                  </Typography>
                </Collapse>
                <Box className="blackbook-button-wrap">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="blackbook-button"
                    onClick={() =>
                      handleBlackBookModal(
                        eventLocation?.[0]?.track?.name,
                        eventLocation?.[0]?.track?.id,
                        "Track"
                      )
                    }
                  >
                    <span style={{ display: "flex" }}>
                      <BlackBookIcon />{" "}
                    </span>{" "}
                    <span style={{ paddingLeft: "8px" }}>
                      {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
                    </span>
                  </Button>
                </Box>
              </Box>
            </Box>
          )}

          {fetchAds(1, "102px", AboveRunnerTable)}
          {!isResult ? (
            <>
              <RunnerTable
                raceId={raceTrackdata?.id}
                runnerInfo={runnerData}
                isrunnnerLoading={isrunnnerLoading}
                isResult={isResult}
                raceTrackdata={raceTrackdata}
                fetchRaceRunner={fetchRaceRunner}
              />
            </>
          ) : (
            <Box className="race-result-detail-wrap">
              <RunnerResultTable
                raceId={raceTrackdata?.id}
                runnerInfo={runnerData}
                isrunnnerLoading={isrunnnerLoading}
                isResult={isResult}
                RaceResultData={RaceResultData}
                raceTrackdata={raceTrackdata}
                fetchRaceRunner={fetchRaceRunner}
              />
            </Box>
          )}
          {fetchAds(2, "102px", AboveOurPartner)}
          <OurPartner />
          {fetchAds(3, "102px", BelowOurPartner)}
        </Box>
      )}
      <BlackBookModal
        open={open}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
      />
    </>
  );
};

export default RunnersTablePage;
